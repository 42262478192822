import { graphql, useStaticQuery } from "gatsby";

export default () => useStaticQuery(graphql`{
    data: markdownRemark ( frontmatter: { identifier: { eq: "privacy" } } ) {
        html
        frontmatter {
            title
            seoSubtitle
            seoDescription
            seoKeywords
            color
        }
    }
}`);


import React from "react";
import SEO from "../components/SEO";
import BasicBlock from "../components/blocks/BasicBlock";

import getPrivacyData from "../queries/getPrivacyData";

export default props => {
    const { data: privacy } = getPrivacyData();

    return (
        <>
        <SEO pageData={ privacy.frontmatter } />
        <BasicBlock title={ privacy.frontmatter.title } html={ privacy.html } color={ privacy.frontmatter.color } />
        </>
    );
};
